import React, { Component } from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Container } from "bloomer/lib/layout/Container";
import { Button } from "bloomer/lib/elements/Button";
import { Link } from "react-router-dom";

export default class AppsFeatured extends Component {
  render() {
    return (
      <div className="featured-app">
        <Container>
          <h1 className="thin">FEATURED</h1>
          <Columns className="mt-20">
            <Column>
              <AppFeaturedItem
                img="/img/egsy01card.jpg"
                title="EGSY01"
                url="/app/egsy01"
              />
            </Column>
            <Column>
              <AppFeaturedItem
                img="/img/909card.jpg"
                title="EGDR909"
                url="/app/egdr909"
              />
            </Column>
          </Columns>
          <Columns>
            <Column>
              <AppFeaturedItem
                img="/img/808card.jpg"
                title="EGDR808"
                url="/app/egdr808"
              />
            </Column>
            <Column>
              <AppFeaturedItem
                img="/img/bpmcard.jpg"
                title="BPM & CHORDS"
                url="/app/bpm_chords"
              />
            </Column>
          </Columns>
        </Container>
      </div>
    );
  }
}

class AppFeaturedItem extends Component {
  render() {
    return (
      <div
        className="app-img"
        style={{
          background: "url(" + this.props.img + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center"
          // width: "100%",
          // height: "100%"
        }}
      >
        <h1 className="app-featured-title">{this.props.title}</h1>
        <Link to={this.props.url}>
          <Button className="app-featured-button carousel-button ">
            More info
          </Button>
        </Link>
      </div>
    );
  }
}
