import React, { Component } from "react";
import Carousel from "./Carousel";
import LatestApp from "./LatestApp";
import InstagramFeed from "./InstagramFeed";
import AppsFeatured from "./AppsFeatured";
import Newsletter from "./Newsletter";

export default class Landing extends Component {
  render() {
    return (
      <div>
        <Carousel />
        <LatestApp />
        <Newsletter />
        <AppsFeatured />
        <InstagramFeed />
      </div>
    );
  }
}
