import React, { Component } from "react";
import { Button } from "bloomer/lib/elements/Button";

export default class Shop extends Component {
  render() {
    return (
      <div>
        <div className="topbar"></div>
        <div id="myShop"></div>;
      </div>
    );
  }
}
