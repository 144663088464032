import React, { Component } from "react";
import logo from "./logo.svg";
import "bulma/css/bulma.css";

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Footer from "./components/Footer";
import MNavbar from "./components/MNavbar";
import Landing from "./components/Landing";
import AppDetail from "./components/AppDetail";
import InstagramFeed from "./components/InstagramFeed";
import AppsFeatured from "./components/AppsFeatured";
import ScrollToTop from "./ScrollToTop";
import Manuals from "./components/Manuals";
import Privacy from "./components/Privacy";
import Shop from "./components/Shop";
import Drumkits from "./components/Drumkits";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <MNavbar />

            <Switch>
              <ScrollToTop location={this.props.location}>
                <Route exact path="/" component={Landing} />
                <Route exact path="/software/" component={Landing} />
                <Route exact path="/app/:appId" component={AppDetail} />
                <Route exact path="/user-manuals" component={Manuals} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/shop" component={Shop} />
                <Route exact path="/additional-content" component={Drumkits} />
              </ScrollToTop>
            </Switch>
            <Footer />
          </div>
        </Router>
        {/* <InstagramFeed /> */}
      </div>
    );
  }
}

export default App;
