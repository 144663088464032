import React, { Component } from "react";
import Slider from "react-slick";

import instafeed from "react-instafeed";

export default class InstagramFeed extends Component {
  state = {
    values: []
  };
  async componentDidMount() {
    const options = {
      accessToken: "7444446530.a8f9fa5.03cb0919df684a648e9bee7d3247acd0",
      clientId: "a8f9fa585650484b9664598554f75a38",
      get: "user", // popular, user
      locationId: null,
      resolution: "standard_resolution", // thumbnail, low_resolution, standard_resolution
      sortBy: "none", // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
      tagName: null,
      userId: 7444446530
    };

    const values = instafeed(options).then(data => {
      this.setState({ values: data });
      console.log("aa d", data);
    });
    // this.setState({ values: });

    // fetch("/json/instagram.json")
    //   .then(r => r.json())
    //   .then(data => {
    //     this.setState({ values: data.values });
    //   });
  }
  render() {
    if (!!this.state) console.log("data", this.state.values.data);
    const values = this.state.values.data;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      autoplay: true,
      className: "instagram-feed"
    };
    return (
      <div>
        <div className="instagram-header">
          <img className="instagram-logo" src="/img/instagram.png" />
          <span className="instagram-text">@elliottGarage</span>
        </div>
        <Slider {...settings}>
          {values &&
            values.map(({ link, id, images }, index) => {
              const image = images["thumbnail"];
              console.log("AA image", image, id);
              return (
                <div key={id}>
                  <a href={link} target="#">
                    <img className="instagram-img" src={image.url} />
                  </a>
                </div>
              );
            })}
          {/* {values.map((v, idx) => (
            <div key={idx}>
              <a href={v.url} target="#">
                <img className="instagram-img" src={v.image} />
              </a>
            </div>
          ))} */}
        </Slider>
      </div>
    );
  }
}
