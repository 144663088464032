import React, { Component } from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Container, Button } from "bloomer";
import { Link } from "react-router-dom";
import Player from "./Player";

export default class LatestApp extends Component {
  state = {
    app: {
      youtube: "https://www.youtube.com/watch?v=WgT07yankWM&feature=youtu.be",
      text:
        "With sEGments slicer/sampler app you can Import any loop/sample/audio file and find the best samples inside it, using the automatic transient detection or manually adding markers<br><br>",
      image: "/img/segmentshome.jpg",
      ios: true,
      link: "app/segments",
      url: "https://apps.apple.com/app/id1513990681"
    },
    app2: {
      youtube: "https://youtu.be/D32WgLkwIo8",
      text:
        "The new hybrid Waveshaping/Wavetable Synth from ElliottGarage!<br><br><b>EG WaveSHAPER</b> is the first synthesizer that lets you create your sound with a finger<br><br>Draw the waveform on the screen or shape a builtin one, mix it with a classic analog oscillator and a sub oscillator and automate all the knobs with 2 drawable LFOs and 2 ENVs<br><br>",
      image: "/img/waveShaper.png",
      ios: true,
      link: "app/waveshaper",
      url: "https://itunes.apple.com/us/app/id1445356061"
    },
    app3: {
      youtube: "https://www.youtube.com/watch?v=LtgcvCKBbss&feature=youtu.be",
      text:
        "The new AU3 Drum Machine/Sampler from ElliottGarage!<br><br><b>EG Pulse</b> comes with more than 40 drumkits and more than 180 grooves already included to cover many different genres from EDM, House, Trap, Hip Hop and Electronic Music to Rock, Metal, Reggae or Jazz<br><br>",
      image: "/img/pulsehome.png",
      ios: true,
      link: "app/egpulse",
      url: "https://apps.apple.com/us/app/eg-pulse/id1478285477"
    },
    egnodes: {
      youtube: "https://youtu.be/lUV4d3OPpYk?si=JjMAXBlFW1h4MWr8",
      text:
        "EG Nodes is a clip based Audio Unit host with sequencing capabilities to play external instruments, Audio Unit or MIDI hardware and create a complete audio project. A powerful and flexible groovebox to perform or create musical projects from scratch in a fast and easy way.",
      image: "/img/egnodeshome.png",
      ios: true,
      link: "app/egnodes",
      url: "https://apps.apple.com/us/app/eg-nodes/id6466639463"
    }
  };
  render() {
    const { app, app2, app3, egnodes } = this.state;

    return (
      <div className="mtb-40">
        <h1 className="mt-40 thin ">LATEST NEWS</h1>

        <div className="app-video-header">
          <Player url={egnodes.youtube} />
        </div>
        {/* <div className="app-video-header">
          <Player url={app2.youtube} />
        </div>
        <div className="app-video-header">
          <Player url={app3.youtube} />
        </div> */}
        <Columns className="latest-app">
          <Column>
            <div className="text-container">
              <h1 className="mt-20 thin subtitle">EG Nodes</h1>
              <h2 dangerouslySetInnerHTML={{ __html: egnodes.text }} />
            </div>
            <div className="mt-20">
              <Link to={egnodes.link}>
                <Button className="carousel-button">More info</Button>
              </Link>
              {!!egnodes.ios ? (
                <a href={egnodes.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/appleStore.png"
                  />
                </a>
              ) : (
                <a href={egnodes.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/googlePlay.png"
                  />
                </a>
              )}
            </div>
          </Column>{" "}
          <Column>
            <img className="latest-app-img mt-20" src={egnodes.image} />
          </Column>
        </Columns>

        <Columns className="latest-app">
          <Column>
            <img className="latest-app-img mt-20" src={app.image} />
          </Column>
          <Column>
            <div className="text-container">
              <h1 className="mt-20 thin subtitle">sEGments</h1>
              <h2 dangerouslySetInnerHTML={{ __html: this.state.app.text }} />
            </div>
            <div className="mt-20">
              <Link to={app.link}>
                <Button className="carousel-button">More info</Button>
              </Link>
              {!!app.ios ? (
                <a href={app.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/appleStore.png"
                  />
                </a>
              ) : (
                <a href={app.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/googlePlay.png"
                  />
                </a>
              )}
            </div>
          </Column>
        </Columns>

        <Columns className="latest-app">
          <Column className="order-2">
            <div className="text-container">
              <h1 className="mt-20 thin subtitle">EG Pulse for iOS</h1>
              <h2 dangerouslySetInnerHTML={{ __html: this.state.app3.text }} />
            </div>
            <div className="mt-20">
              <Link to={app3.link}>
                <Button className="carousel-button">More info</Button>
              </Link>
              {!!app3.ios ? (
                <a href={app3.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/appleStore.png"
                  />
                </a>
              ) : (
                <a href={app3.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/googlePlay.png"
                  />
                </a>
              )}
            </div>
          </Column>
          <Column className="order-1">
            <img className="latest-app-img mt-20" src={app3.image} />
          </Column>
        </Columns>

        <Columns className="latest-app">
          <Column>
            <img className="latest-app-img mt-20" src={app2.image} />
          </Column>
          <Column>
            <div className="text-container">
              <h1 className="mt-20 thin subtitle">EG WaveSHAPER</h1>
              <h2 dangerouslySetInnerHTML={{ __html: this.state.app2.text }} />
            </div>
            <div className="mt-20">
              <Link to={app2.link}>
                <Button className="carousel-button">More info</Button>
              </Link>
              {!!app2.ios ? (
                <a href={app2.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/appleStore.png"
                  />
                </a>
              ) : (
                <a href={app2.url} target="#">
                  <img
                    className="carousel-store-img"
                    src="/img/googlePlay.png"
                  />
                </a>
              )}
            </div>
          </Column>
        </Columns>

        {/* <Columns className="latest-app">
          <Column>
            <div className="text-container">
              <h1 className="mt-20 thin subtitle">EGDR808 for ANDROID</h1>
              <h2 dangerouslySetInnerHTML={{ __html: this.state.app.text }} />
            </div>
            <div className="mt-20">
              <Link to={app.link}>
                <Button className="carousel-button">More info</Button>
              </Link>
              {!!app.ios ? (
                <a href={app.url} target="#">
                  <img className="carousel-store-img" src="/img/appleStore.png" />
                </a>
              ) : (
                <a href={app.url} target="#">
                  <img className="carousel-store-img" src="/img/googlePlay.png" />
                </a>
              )}
            </div>
          </Column>
          <Column>
            <img className="latest-app-img mt-20" src={app.image} />
          </Column>
        </Columns> */}
      </div>
    );
  }
}
