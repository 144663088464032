import React, { Component } from "react";
import ReactPlayer from "react-player";

export default class Player extends Component {
  render() {
    const { url } = this.props;
    console.log("URL", url);
    return (
      <div className="player-wrapper">
        <ReactPlayer url={url} width="100%" height="100%" />
      </div>
    );
  }
}
