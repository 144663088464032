import React, { Component } from "react";
import { Container } from "bloomer/lib/layout/Container";
import { Link } from "react-router-dom";
import { Button } from "bloomer";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";

export default class Drumkits extends Component {
  state = { segments: [] };
  componentDidMount() {
    fetch("/json/drumkits.json")
      .then(r => r.json())
      .then(drumkits => {
        this.setState(drumkits);
      });

    fetch("/json/segments.json")
      .then(r => r.json())
      .then(segments => {
        this.setState(segments);
      });
  }

  renderDrumkit(d) {
    console.log("aa d", d);
    return (
      <Columns isMobile>
        <Column>
          <div style={{ float: "right" }}>
            <h1 className="ta-r">{d.title}</h1>
            <h2 className="ta-r" style={{ marginTop: "0px" }}>
              {d.subtitle}
            </h2>
          </div>
        </Column>
        <Column>
          <div style={{ float: "left" }}>
            <Button href={d.url} className="carousel-button">
              Download
            </Button>
          </div>
        </Column>
      </Columns>
    );
  }

  render() {
    if (!this.state.drumkits) return <h1> LOADING </h1>;

    return (
      <div>
        <div
          className="img-header"
          style={{
            background: "url(img/header/pulseHeader.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center center"
            // width: "100%",
            // height: "100%"
          }}
        />
        <div>
          <h1 className="app-detail-name">Additional Contents</h1>
        </div>
        <Container className="mtb-40">
          <h1 className="mb-40">sEGments Samples Pack</h1>
          {this.state.segments.map(d => {
            return this.renderDrumkit(d);
          })}
          <h1 className="mb-40">Pulse Drumkits</h1>
          {this.state.drumkits.map(d => {
            return this.renderDrumkit(d);
          })}
        </Container>
      </div>
    );
  }
}
