import React, { Component } from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Container } from "bloomer/lib/layout/Container";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <Container className="mtb-80">
        <Link to="/" className="mb-20">
          <img className="logo-footer mb-20" src={process.env.PUBLIC_URL + "/img//logoGrey.png"} />
        </Link>
        <Columns>
          <Column>
            <a href="http://www.facebook.com/ElliottGarageSoftware">
              <img className="social-link" src={process.env.PUBLIC_URL + "/img/social/Facebook.png"} />
            </a>
            <a href="http://www.twitter.com/ElliottGarage" target="#">
              <img className="social-link" src={process.env.PUBLIC_URL + "/img/social/Twitter.png"} />
            </a>
            <a href="http://www.youtube.com/ElliottGarage" target="#">
              <img className="social-link" src={process.env.PUBLIC_URL + "/img/social/YouTube.png"} />
            </a>
            <a href="https://www.instagram.com/elliottgarage" target="#">
              <img className="social-link" src={process.env.PUBLIC_URL + "/img/social/Instagram.png"} />
            </a>
            <a href="mailto:info@elliottgarage.com" target="#">
              <img className="social-link" src={process.env.PUBLIC_URL + "/img/social/Mail.png"} />
            </a>
            {/* <a
              href="https://www.linkedin.com/company/elliott-garage"
              target="#"
            >
              <img
                className="social-link"
                src="/img/social/LinkedIn.png"
              />
            </a> */}
          </Column>
        </Columns>{" "}
        <div className="text-container">
          <h3 className="subtitle footer-text thin mt 20">
            ElliottGarage © 2018 | Andrea Angelini | IT 15132681006 | Via Tasso 32 - 00185 Rome (Italy) | <Link to="/privacy">Privacy Policy</Link>
          </h3>
        </div>{" "}
      </Container>
    );
  }
}
