import React, { Component } from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Container } from "bloomer/lib/layout/Container";
import Player from "./Player";

export default class AppDetail extends Component {
  state = {
    egdr808: {
      name: "EGDR808 Drum Machine",
      storeUrl: "https://itunes.apple.com/us/app/egdr808-hd/id454709863?mt=8",
      googlePlay:
        "https://play.google.com/store/apps/details?id=com.elliottgarage.newproject",
      header: "/img/header/808header.jpg"
    },
    egdr909: {
      name: "EGDR909 Drum Machine",
      storeUrl:
        "https://itunes.apple.com/us/app/egdr909-909-drum-machine/id916294557?mt=8",
      header: "/img/header/909header.jpg"
    },
    egsy01: {
      name: "EGSY01 Analog Synth",
      storeUrl:
        "https://itunes.apple.com/us/app/egsy01-analog-synth/id687256735?mt=8",
      header: "/img/header/egsy01header.jpg"
    },
    egdr606: {
      name: "EGDR606 Drum Machine",
      header: "/img/header/606header.jpg",
      storeUrl:
        "https://itunes.apple.com/us/app/egdr606-606-drum-machine/id1274566095?mt=8"
    },
    eglm1: {
      name: "EGLM1 Drum Machine",
      header: "/img/header/eglm1header.jpg",
      storeUrl:
        "https://itunes.apple.com/us/app/eglm1-lm1-drum-machine/id943634853?mt=8"
    },
    formante: {
      name: "FORMANTE Formant filter",
      header: "/img/header/formanteheader.jpg",
      storeUrl: "https://itunes.apple.com/us/app/formante/id1421830251?mt=8"
    },
    bpm_chords: {
      name: "BPM & CHORDS Analyzer",
      header: "/img/header/bpmheader.jpg",
      storeUrl:
        "https://itunes.apple.com/us/app/bpm-chords-live-analyzer-dj-and-musicians-tool/id1249488898?mt=8"
    }
  };

  componentWillUnmount() {
    console.log("UNMOUNT");
  }
  componentDidMount() {
    console.log("MOUNT");
    const app = this.props.match.params.appId;
    console.log("App", app);

    fetch("/json/data.json")
      .then(r => r.json())
      .then(data => {
        this.setState(data);
      });
  }

  render() {
    const app = this.state[this.props.match.params.appId];
    console.log("App", app);
    if (!app) return <h1> LOADING </h1>;

    return (
      <div>
        <div
          className="img-header"
          style={{
            background: "url(" + app.header + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
            // width: "100%",
            // height: "100%"
          }}
        />
        <div>
          <h1 className="app-detail-name">{app.name}</h1>
        </div>
        <Container className="mtb-40">
          <Columns isCentered>
            <Column>
              <Store storeUrl={app.storeUrl} androidUrl={app.googlePlay} />
            </Column>
            {!!app.roadmap && (
              <Column>
                {" "}
                <RoadMap url={app.roadmap} />
              </Column>
            )}
          </Columns>
          {!!app.header_youtube && (
            <div className=" app-video-header ">
              <Player url={app.header_youtube} />
            </div>
          )}

          <Columns className="mt-40">
            <Column>
              <Description description={app.description} />
            </Column>
            <Column>
              <img src={app.image1} />
            </Column>
          </Columns>
          {!!app.features && (
            <div className="featured-app">
              <h1 className="thin mb-20">OTHER FEATURES</h1>

              <Columns>
                <Column>
                  <img src={app.image2} />
                </Column>
                <Column>
                  <Features values={app.features} />
                </Column>
              </Columns>
            </div>
          )}

          {!!app.youtube && (
            <div className="app-video">
              <h1 className="thin mtb-20">Videos</h1>
              <Player url={app.youtube} />
            </div>
          )}
          {!!app.youtube2 && (
            <div className="mt-20">
              <div className="app-video">
                <Player url={app.youtube2} />
              </div>
            </div>
          )}
          {!!app.youtube3 && (
            <div className="mt-20">
              <div className="app-video">
                <Player url={app.youtube3} />
              </div>
            </div>
          )}
          {!!app.youtube4 && (
            <div className="mt-20">
              <div className="app-video">
                <Player url={app.youtube4} />
              </div>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

class Features extends Component {
  render() {
    const { values } = this.props;
    return (
      <ul className="text-container">
        {values.map((v, i) => (
          <li key={i}>{v}</li>
        ))}
      </ul>
    );
  }
}

class Description extends Component {
  render() {
    const { description } = this.props;

    return (
      <h2
        className="text-container"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }
}

class Store extends Component {
  render() {
    const { storeUrl } = this.props;
    const { androidUrl } = this.props;
    return (
      <div>
        <h1 className="app-download-text">Download on</h1>
        {!!androidUrl ? (
          <div>
            <a href={storeUrl} target="#">
              <img className="app-download-img" src="/img/appleStore.png" />
            </a>
            <a href={androidUrl} target="#">
              <img className="app-download-img" src="/img/googlePlay.png" />
            </a>
          </div>
        ) : (
          <a href={storeUrl} target="#">
            <img className="app-download-img" src="/img/appleStore.png" />
          </a>
        )}
      </div>
    );
  }
}
class RoadMap extends Component {
  render() {
    const { url } = this.props;
    return (
      <div>
        <h1 className="app-download-text">Roadmap</h1>

        <a href={url} target="#">
          {url}
        </a>
      </div>
    );
  }
}
