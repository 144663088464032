import React, { Component } from "react";
import MailchimpService from "../MailchimpService";
import { Input } from "bloomer/lib/elements/Form/Input";
import { Field } from "bloomer/lib/elements/Form/Field/Field";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Help } from "bloomer/lib/elements/Form/Help";
import { Button } from "bloomer/lib/elements/Button";
import { Container } from "bloomer/lib/layout/Container";

export default class Newsletter extends Component {
  state = {
    email: null,
    emailDirty: false,
    emailValid: true,
    emailSent: false,
  };

  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.subscribeMailchimp = this.subscribeMailchimp.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onChangeFormElement = this.onChangeFormElement.bind(this);
  }

  subscribeMailchimp(email) {
    MailchimpService.subscribe({
      EMAIL: email,
    });
  }

  submitForm(e) {
    if (e) e.preventDefault();

    const formValid = this.validateEmail(this.state.email);
    console.log("AA formValid", formValid);
    if (!formValid) return;

    this.subscribeMailchimp(this.state.email);
    this.setState({ emailSent: true });
  }

  validateEmail(email) {
    if (!email || email.length <= 5) return false;

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onChangeFormElement(event) {
    const s = {};
    s[event.target.name] = event.target.value;
    s[event.target.name + "Dirty"] = true;
    this.setState(s);
  }

  validateForm() {
    this.setState({
      emailValid: this.validateEmail(this.state.email),
    });
  }

  render() {
    return (
      <div className="newsletter">
        <Container>
          <Columns>
            <Column>
              <h1 className="thin newsletter" style={{ color: "white" }}>
                NEWSLETTER
              </h1>
            </Column>
            <Column>
              <Columns>
                <Column>
                  <Field>
                    <Input
                      onChange={this.onChangeFormElement}
                      name="email"
                      type="email"
                      value={this.state.email}
                      placeholder="eMail"
                      className={!this.state.emailValid && this.state.emailDirty ? "has-error" : ""}
                    />
                    {!this.state.emailValid && this.state.emailDirty ? <Help isColor="danger">please check your email!</Help> : null}
                    {this.state.emailSent ? <Help isColor="success">email subscribed!</Help> : null}
                  </Field>
                </Column>
                <Column>
                  <Button disabled={!this.state.emailValid || this.state.emailSent} className=" carousel-button " onClick={this.submitForm}>
                    SUBMIT
                  </Button>
                </Column>
              </Columns>
            </Column>
          </Columns>

          {/* <div style={{ maxWidth: "400px" }}>
   
          </div> */}
        </Container>
      </div>
    );
  }
}
