import React, { Component } from "react";
import "./../css/carousel.css";

import Slider from "react-slick";
import { Container } from "bloomer/lib/layout/Container";
import { Button } from "bloomer/lib/elements/Button";
import { Hero, HeroBody } from "bloomer";
import { Link } from "react-router-dom";

export default class Carousel extends Component {
  state = {
    images: [
      {
        image: "/img/egnodesHeader.png",
        title: "EG Nodes",
        subtitle: "Host/Sequencer/AU3",
        ios: true,
        link: "/app/egnodes",
        url: "https://apps.apple.com/us/app/eg-nodes/id6466639463"
      },
      {
        image: "/img/segmentsHeader.jpg",
        title: "sEGments",
        subtitle: "AU3 Slicer/Sampler",
        ios: true,
        link: "/app/segments",
        url: "https://apps.apple.com/app/id1513990681"
      },
      {
        image: "/img/pulseHeader.png",
        title: "EG Pulse",
        subtitle: "AU3 Drum Machine/Sampler",
        ios: true,
        link: "/app/egpulse",
        url: "https://apps.apple.com/us/app/eg-pulse/id1478285477"
      },
      {
        image: "/img/waveShaperHeader.png",
        title: "EG WaveSHAPER",
        subtitle: "Hybrid Wavetable/Waveshaping Synth",
        ios: true,
        link: "/app/waveshaper",
        url:
          "https://itunes.apple.com/us/app/eg-waveshaper/id1445356061?l=it&ls=1&mt=8"
      },
      {
        image: "/img/carousel/carousel2.jpg",
        title: "EGSY01 Synth",
        subtitle: "Virtual analog synthesizer",
        ios: true,
        link: "/app/egsy01",
        url:
          "https://itunes.apple.com/us/app/egsy01-analog-synth/id687256735?mt=8"
      },
      {
        image: "/img/carousel/carousel3.jpg",
        title: "EGDR909",
        subtitle: "Vintage Drum Machine",
        ios: true,
        link: "/app/egdr909",
        url:
          "https://itunes.apple.com/us/app/egdr909-909-drum-machine/id916294557?mt=8"
      },
      {
        image: "/img/carousel/carousel4.jpg",
        title: "Formante",
        subtitle: "Au3 Vowel & Formant filter",
        link: "/app/formante",
        ios: true,
        url: "https://itunes.apple.com/us/app/formante/id1421830251"
      }
    ]
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      // fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      pauseOnHover: false,
      autoplaySpeed: 5000,
      useTransform: false
    };
    return (
      <div>
        <Slider {...settings}>
          {this.state.images.map(i => (
            <div key={i}>
              <CarouselItem val={i} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

class CarouselItem extends Component {
  render() {
    const { val } = this.props;
    return (
      <div>
        <h3>
          <div
            className="carousel"
            style={{
              background: "url(" + val.image + ")",
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            <Hero>
              <HeroBody>
                <Container className="left-column">
                  <h1 className="carousel-title">{val.title}</h1>
                  <h2 className="carousel-subtitle">{val.subtitle}</h2>
                  <div className="carousel-button-group">
                    <Link to={val.link}>
                      <Button className="carousel-button">More info</Button>
                    </Link>
                    {!!val.ios ? (
                      <a href={val.url} target="#">
                        <img
                          className="carousel-store-img"
                          src="/img/appleStore.png"
                        />
                      </a>
                    ) : (
                      <a href={val.url} target="#">
                        <img
                          className="carousel-store-img"
                          src="/img/googlePlay.png"
                        />
                      </a>
                    )}
                  </div>
                </Container>
              </HeroBody>
            </Hero>
          </div>
        </h3>
      </div>
    );
  }
}
