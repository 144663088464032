import React, { Component } from "react";
import "./../css/navbar.css";

import { Navbar } from "bloomer/lib/components/Navbar/Navbar";
import { NavbarBrand } from "bloomer/lib/components/Navbar/NavbarBrand";
import { NavbarMenu } from "bloomer/lib/components/Navbar/NavbarMenu";
import { NavbarStart } from "bloomer/lib/components/Navbar/NavbarStart";
import { NavbarItem } from "bloomer/lib/components/Navbar/NavbarItem";
import { NavbarDropdown } from "bloomer/lib/components/Navbar/NavbarDropdown";
import { NavbarBurger } from "bloomer/lib/components/Navbar/NavbarBurger";
import { NavbarDivider } from "bloomer/lib/components/Navbar/NavbarDivider";
import { NavbarEnd } from "bloomer/lib/components/Navbar/NavbarEnd";
import { NavbarLink } from "bloomer/lib/components/Navbar/NavbarLink";
import { Link } from "react-router-dom";

export default class MNavbar extends Component {
  state = {
    isActive: false,
    transparent: true
  };

  constructor(props) {
    super(props);
    this.updateTransparencyOnScroll = this.updateTransparencyOnScroll.bind(
      this
    );
  }

  updateTransparencyOnScroll() {
    if (window.scrollY < 60) {
      this.setState({ transparent: true });
    } else {
      this.setState({ transparent: false });
    }
  }

  componentDidMount() {
    this.updateTransparencyOnScroll();
    const observer = document.addEventListener(
      "scroll",
      this.updateTransparencyOnScroll
    );
    this.setState({ observer: observer });
  }

  componentWillUmount() {
    if (this.state.observer) this.state.observer();
  }

  render() {
    return (
      <Navbar className={this.state.transparent ? "navbar-transparent" : ""}>
        <NavbarBrand>
          <Link to="/">
            <NavbarItem>
              <img src="/img/logoWhite.png" />
            </NavbarItem>
          </Link>
          <NavbarBurger
            isActive={this.state.isActive}
            onClick={() => this.setState({ isActive: !this.state.isActive })}
          />
        </NavbarBrand>

        <NavbarMenu
          isActive={this.state.isActive}
          onClick={() => this.setState({ isActive: !this.state.isActive })}
        >
          <NavbarEnd className="scrollable-navbar">
            <NavbarItem hasDropdown isHoverable>
              <NavbarLink>Software & Apps</NavbarLink>
              <NavbarDropdown>
                <NavbarItem>HOST & Sequencer</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/egnodes"
                >
                  EG Nodes
                </NavbarItem>
                <NavbarDivider />
                <NavbarItem>Sampler</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/segments"
                >
                  sEGments
                </NavbarItem>
                <NavbarDivider />
                <NavbarItem>Drum machines</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/egpulse"
                >
                  EG Pulse
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/egdr808"
                >
                  EGDR808
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/egdr909"
                >
                  EGDR909
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/egdr606"
                >
                  EGDR606
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/eglm1"
                >
                  EGLM1
                </NavbarItem>
                <NavbarDivider />
                <NavbarItem>Synths</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/waveshaper"
                >
                  WaveSHAPER
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/egsy01"
                >
                  EGSY01
                </NavbarItem>
                <NavbarDivider />
                <NavbarItem>Effects</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/formante"
                >
                  Formante
                </NavbarItem>
                <NavbarDivider />
                <NavbarItem>Utilities</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/app/bpm_chords"
                >
                  BPM & Chords
                </NavbarItem>
                <NavbarDivider />
                <NavbarItem>VST & AU</NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  target="#"
                  href="http://mastersonicarts.uniroma2.it/ricerca/texture-2/"
                >
                  Texture
                </NavbarItem>
              </NavbarDropdown>
            </NavbarItem>

            <NavbarItem hasDropdown isHoverable>
              <NavbarLink>Support</NavbarLink>
              <NavbarDropdown>
                <NavbarItem
                  className="navbar-item-listelement"
                  target="#"
                  href="https://shop.spreadshirt.it/elliottgarage-it"
                >
                  SHOP
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="mailto:info@elliottgarage.com"
                >
                  Contact Us
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/user-manuals"
                >
                  User Manuals
                </NavbarItem>
                <NavbarItem
                  className="navbar-item-listelement"
                  href="/additional-content"
                >
                  Additional Contents
                </NavbarItem>
                <NavbarItem className="navbar-item-listelement" href="/privacy">
                  Privacy Policy
                </NavbarItem>
              </NavbarDropdown>
            </NavbarItem>

            {/* <NavbarItem href="#/">Store</NavbarItem> */}
          </NavbarEnd>
        </NavbarMenu>
      </Navbar>
    );
  }
}
