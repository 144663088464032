import React, { Component } from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { Container } from "bloomer/lib/layout/Container";
import Carousel from "./Carousel";

export default class Manuals extends Component {
  state = {
    values: []
  };

  componentWillUnmount() {
    console.log("UNMOUNT");
  }
  componentDidMount() {
    console.log("MOUNT");

    fetch("/json/manuals.json")
      .then(r => r.json())
      .then(data => {
        this.setState(data);
      });
  }

  render() {
    return (
      <div>
        <Carousel />

        <h1 className="mt-40 thin ">USER MANUALS</h1>

        <Columns className="mt-40 user-manual" isMultiline isCentered>
          {this.state.values.map(v => (
            <Column
              style={{ padding: "0px", margin: "5px", width: "100px" }}
              isSize={1}
            >
              <a href={v.url} target="#">
                <img className="img-manual" src={v.icon} />
                <h2>{v.text}</h2>
              </a>
            </Column>
          ))}
        </Columns>
      </div>
    );
  }
}
