import jsonp from "jsonp";
import toQueryString from "to-querystring";
const getAjaxUrl = url => url.replace("/post?", "/post-json?");

export default class MailchimpService {
  static subscribe(data) {
    const params = toQueryString(data);
    const listId = "3a60e9b3bb";
    const url = getAjaxUrl("https://elliottgarage.us20.list-manage.com/subscribe/post?u=c987001922831a59a7c37c112&id=" + listId) + "&" + params;

    jsonp(
      url,
      {
        param: "c"
      },
      (err, data) => {
        if (err) {
          console.log("Mailchimp error", err);
        } else if (data.result !== "success") {
          console.log("Mailchimp error", data.msg);
        } else {
          console.log("Mailchimp success");
        }
      }
    );
  }
}
